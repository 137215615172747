<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container
      v-if="$route.name === 'index' && $voicer.isOpenSite() === false"
      class="pa-0"
    >
      <v-sheet
        class="message_welcome_sir text-alternate"
        :class="{
          'pl-3 pr-3 pt-2': $voicer.isMobile,
          'pb-2': $voicer.isMobile && !$slots['cta'],
          'pa-4': $voicer.isDesktopOrTablet,
          headline: $voicer.isDesktopOrTablet,
          'body-2': $voicer.isMobile,
        }"
      >
        {{ $t('glob_hellosir', { userName }) }}
      </v-sheet>
    </v-container>
    <w-block name="cta" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
  },
}
</script>

<style lang="scss" scoped>
.message_welcome_sir {
  border-radius: 6px !important;
}
</style>
